import {JobIncrement, JobRun} from "../Api/ApiClient";
import ApiClient from "../Api/ApiClient";
import {Table} from "react-bootstrap";
import QCMove from "./QCMove";

import {LoaderFunctionArgs, useLoaderData} from "react-router-dom";
import ExoClient, {ExoSalesOrder} from "../Api/ExoClient";
import React from "react";

interface PipeData {
    index: number | null,
    job: JobRun,
    increment: JobIncrement,
    salesOrder: ExoSalesOrder | null
}

export async function loadPipe({ request, params } : LoaderFunctionArgs) {
    let { jobId, eventId } = params;
    let index = new URL(request.url).searchParams.get("i");
    let client = new ApiClient();
    let job = await client.GetJob(jobId!);
    let increment = await client.GetIncrement(jobId!, eventId!);
    let salesOrder = job.Job.WorksOrder?.SalesOrder ?? job.Job.SalesOrderLine?.Order ?? job.Job.SalesOrder;
    if (salesOrder) {
        // Load latest sales order
        salesOrder = await new ExoClient().getSalesOrder(salesOrder.Number);
    }
    return {
        job,
        increment,
        salesOrder,
        index: index ? parseInt(index) : null
    } as PipeData;
}

// Checks if a value is null, undefined, or an empty string
function isNothing(input: any): boolean {
    return !input || (typeof input === 'string' && input.trim().length === 0);
}

function RenderValue(value: any) : JSX.Element | null {
    if (isNothing(value)) {
        // Don't render empty values
        return null;
    }
    if (value instanceof Date) {
        return <>{value.toLocaleDateString("en-AU")}</>;
    }
    else if (Array.isArray(value)){
        let list : JSX.Element[] = [];
        for (let i = 0; i < value.length; i++) {
            list.push(<span key={`span${i}`}>{RenderValue(value[i])}</span>);
            list.push(<br key={`break${i}`}/>);
        }
        list.pop(); //Remove last break
        return <>{list}</>;
    }
    return <>{value}</>;
}

export default function PipeView() {
    //TODO: redirect to new pipe (from results)

    let {index,job,increment,salesOrder} = useLoaderData() as PipeData;

    const salesOrderQty = salesOrder?.Lines
        .filter(l => l.StockCode === job.Job.SKU.Stockcode)
        .reduce((a, b) => a + b.QtyOrdered, 0);

    const freight = [salesOrder?.ShippingDetails];
    for (let line of salesOrder?.Lines ?? []) {
        if (line.StockItem?.Group.Name === "FREIGHT") {
            freight.push(line.Description);
        }
    }

    let props: {[index:string]:any} = {
        Date: increment.Timestamp.toDisplayDateTimeString(),
        Factory: job.Line.Factory.Name,
        Line: 'L' + job.Line.Name,
        Kilos: increment.Scrap?.Kilos?.toLocaleString(),
        //"Account No": customer?.AccountNo,

        //Coil labels are printed individually
        //Show quantity if qty > 1 and not a coil
        "Label Qty": increment.Pipe && increment.Pipe.Quantity > 1 && !job.Job.SKU.IsCoil ? increment.Pipe?.Quantity : null,
        "Qty Ordered": salesOrderQty,
        "Qty Produced": job.PassQty,

        "Job": job.Job.Name,
        Customer: salesOrder?.Customer.Name,
        "Sales Order": salesOrder?.Number,
        "Works Order": job.Job.WorksOrder?.Number,
        "Batch No": job.BatchNo.Full,
        "Stock Code": job.Job.SKU.Stockcode,
        Description: job.Job.SKU.Description,
        Material: job.Job.SKU.Product.Material.SKUDescription.Hide ? null : job.Job.SKU.Product.Material.Code,

        "Due Date": salesOrder?.DueDate ?? job.Job.DueDate,
        "Salesperson": salesOrder?.Salesperson?.Name,

        "Freight": freight.filter(x => x),

        "Delivery Address": salesOrder?.Address,

        "Nested Coil": job.Config?.CoilCim?.IsNested === true ? "Yes" : null,
    }

    const style: React.CSSProperties = {
        paddingTop: '0.2em',
        paddingBottom: '0.2em'
    }
    let list : JSX.Element[] = [];
    let i = 0;
    for (let prop in props) {
        i++;
        const element = RenderValue(props[prop]);
        if (element == null) {
            continue;
        }
        list.push(
            <tr key={i++}>
                <td style={style}><b>{prop}</b></td>
                <td style={style}>{element}</td>
            </tr>);
    }

    return (
        <div>
            {increment.Deleted ? <h1 className={"alert alert-danger"}>Increment Deleted</h1> : null }
            <div>
                {
                    increment.Pipe?.Label && index != null
                        ? <h1 style={{marginBottom: "1em", fontWeight: 'bold'}}>{increment.Pipe.Label.QtyDescriptions[index]}</h1>
                        : increment.Pipe != null && !increment.Pipe.Pass ? <h1>Hold</h1> : null
                }

                {increment.Scrap != null ? <h1>Scrap</h1> : null}
                <Table borderless striped>
                    <tbody>
                    {list}
                    </tbody>
                </Table>
                <QCMove increment={increment} />
            </div>
        </div>
    );
}