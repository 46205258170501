import HttpClient from "../Framework/HttpClient";
import ApiConfiguration from "./Configuration";
import {z} from "zod";

export const ExoUserSchema = z.object({
    Number: z.number(),
    Name: z.string().nullish(),
    ShortName: z.string().nullish(),
});

export const ExoStockItemSchema = z.object({
    Code: z.string(),
    Description: z.string().nullish(),
    Group: z.object({
        Number: z.number(),
        Name: z.string()
    })
})

export const ExoCustomerSchema = z.object({
    AccountNo: z.number(),
    Name: z.string().nullish(),
    Active: z.boolean(),
    Phone: z.string().nullish(),
    Email: z.string().nullish(),
    DeliveryAddress: z.array(z.string()).nullish(),
    Address: z.array(z.string()).nullish()
});

const ExoSalesOrderSchemaBase = z.object({
    Number: z.number(),
    Status: z.string(),
    OrderDate: z.coerce.date(),
    DueDate: z.coerce.date(),
    Customer: ExoCustomerSchema,
    Salesperson: ExoUserSchema.nullish(),
    ShippingDetails: z.string().nullish(),
    Address: z.array(z.string()).nullish(),
});


export const ExoSalesOrderLineSchema = z.object({
    Description: z.string().nullish(),
    StockCode: z.string().nullish(),
    DueDate: z.coerce.date(),
    QtyOrdered: z.number(),
    QtySupplied: z.number(),
    QtyInvoiced: z.number(),
    StockItem: ExoStockItemSchema.nullish(),
    Order: ExoSalesOrderSchemaBase.nullish()
});

export const ExoSalesOrderSchema = ExoSalesOrderSchemaBase.extend({
    Lines: ExoSalesOrderLineSchema.array()
})

export const ExoWorksOrderSchema = z.object({
    Number: z.number(),
    SalesOrder: ExoSalesOrderSchemaBase.nullish(),
});

export type ExoSalesOrder = z.infer<typeof ExoSalesOrderSchema>;
export type ExoCustomer = z.infer<typeof ExoCustomerSchema>;
export type ExoSalesOrderLine = z.infer<typeof ExoSalesOrderLineSchema>;

export default class ExoClient extends HttpClient {
    constructor() {
        super(ApiConfiguration.Exo);
    }

    async getSalesOrder(id: number, includeLines: boolean = true): Promise<ExoSalesOrder | null> {
        try {
            return await this.Get(`api/exo/SalesOrders/${id}?includeLines=${includeLines}`, ExoSalesOrderSchema) as ExoSalesOrder;
        }
        catch (e) {
            // Sales order may have been deleted since the job was created
            if (e instanceof Error && e.message && e.message.includes("returned 404")) {
                console.warn("Sales order not found", id);
                return null;
            }
            throw e;
        }
    }
}